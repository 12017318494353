import { css } from "@nju/css";

export const styles = {
  wrapper: css({
    position: "relative",
    width: "100%",
    borderWidth: "$2",
    borderStyle: "solid",
    borderColor: "$colors$geyser",
    borderRadius: 27.5,
    px: "$25",
    display: "flex",
    alignItems: "center",
    backgroundColor: "$white",
    variants: {
      state: {
        error: {
          borderColor: "$tiaMaria",
        },
      },
      focus: {
        true: {
          borderColor: "$ripeLemon",
          boxShadow: "$basic $colors$ripeLemon",
        },
      },
      disabled: {
        true: {
          borderColor: "$geyser",
          backgroundColor: "$porcelain",
        },
      },
      background: {
        white: {
          backgroundColor: "$white",
        },
      },
    },
  }),
  label: css({
    pointerEvents: "none",
    position: "absolute",
    top: "50%",
    left: 0,
    color: "$geyser",
    borderRadius: "$4",
    fontSize: "$16",
    backgroundColor: "transparent",
    transform: `translateY(-50%)`,
    zIndex: "$2",
    transition:
      "$color ease-in, font-size 0.15s ease-in, $bgColor ease-in, $transform ease-in",
    "@fromTablet": {
      fontSize: "$20",
    },
    variants: {
      offside: {
        true: {
          color: "$daintree",
          fontSize: "$14",
          backgroundColor: "$white",
          transform: `translateY(-210%)`,
        },
      },
      labelBackground: {
        white: {
          backgroundColor: "$white",
        },
      },
    },
  }),
  inputWrapper: css({
    flex: 1,
    position: "relative",
  }),
  input: css({
    textAlign: "left",
    backgroundColor: "transparent",
    border: "none",
    position: "relative",
    zIndex: "$1",
    width: "100%",
    fontSize: "$16",
    height: 50,
    filter: "none",
    "@fromTablet": {
      fontSize: "$20",
      height: 55,
    },
    "&:-webkit-autofill": {
      boxShadow: "0 0 0px 1000px #fff inset",
      backgroundColor: "#fff",
      backgroundClip: "content-box",
    },
    "&::placeholder": {
      color: "$geyser",
    },
  }),
  prefix: css({
    color: "$geyser",
    fontSize: "$20",
    pr: "$10",
  }),
  suffix: css({
    color: "$daintree",
    fontSize: "$20",
    pl: "$10",
    display: "flex",
  }),
};
